<template>
  <v-layout column :class="`${this.$vuetify.theme.dark ? 'primary-background' : 'secondary-background'}`">
    <v-layout pa-4 align-center justify-center v-if="loadingServices">
      <h1>Carregando conversas do {{ currentServiceType }} para {{currentService.name}}, aguarde..</h1>
    </v-layout>
    <v-layout pa-4 align-center justify-center v-else>
      <h1>Atendimentos via {{ currentServiceType }} disponíveis para você  :)</h1>
    </v-layout>
    <v-layout 
      v-if="loadingServices"
      justify-center
      class="pt-10"
      :class="`${this.$vuetify.theme.dark ? 'primary-background' : 'secondary-background'}`"
    >
      <v-progress-circular
        :rotate="360"
        :size="200"
        :width="10"
        :value="Math.round((108 / temporaryLoader) * sessions.length) || 0"
        color="teal"
      >
        <b>{{ Math.round((100 / temporaryLoader) * sessions.length) || 0 }} %</b>
      </v-progress-circular>
    </v-layout>
    <v-layout flex wrap lg v-else>
      <v-card
        v-for="(item, i) in filteredServices"
        v-bind:key="i"
        class="rounded-card tertiary-background"
        @click="openService(item)"
        min-height="350"
        min-width="500"
        elevation="5"
      > 
        <v-img
          :aspect-ratio="16/9"
          height="250"
          width="500"
          :src="item.assets_url"
        ></v-img>
        <v-layout column>
          <v-layout
            pa-3 
            align-center
            height="100"
          >
            <v-layout flex>
              <h2>{{item.name}}</h2>
            </v-layout>
            <v-chip
              x-big
              outlined
              class="mt-1 grow-0 shrink-1 align-self-end"
              :color="item.active ? 'green' : 'red'"
              ><span> <strong>{{ item.active ? 'ATIVO' : 'INATIVO' }} </strong></span></v-chip
            >
          </v-layout>
          <v-layout>
            <v-layout
              pl-3
              align-center
            >
              <span>{{item.description}}</span>
            </v-layout>
            <v-layout justify-end pr-3 class="align-self-end">
              <span> <strong> Pendentes: </strong>{{item.pendings}}</span>
            </v-layout>
          </v-layout>
        </v-layout>
      </v-card>
    </v-layout>
  </v-layout>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  async created() {
    await this.getServices();
    await this.getServicesTypes();
    await this.getServicesByType()
    await this.checkUrlType()
    
    setInterval(this.getServicesByType, 30000)
    setInterval(() => {this.getServices()}, 30000)
  },
  data() {
    return {
      loadingServices: false,
      servicesFiltered: [],
    };
  },
  computed: {
    ...mapGetters('rasaSessions', {
      loading: 'loading',
      sessions: "sessions",
      temporaryLoader: "temporaryLoader",  
    }),
    ...mapGetters("rasaServices", {
      services: "services",
      currentServiceType: "currentServiceType",
      currentService: "currentService",
      filteredServices: "filteredServices",
      prioritizedAndTransformTypes: "prioritizedAndTransformTypes",
    }),
  },
  methods: {

    ...mapActions("rasaSessions", {
      registerConnection: "registerConnection",
      getPendingsCount: "getPendingsCount",

    }),
    ...mapMutations("rasaSessions", {
      setSessions: "setSessions",
    }),
    ...mapMutations("rasaServices", {
      setCurrentService: "setCurrentService",
      setCurrentServiceType: "setCurrentServiceType",
    }),
    ...mapActions("rasaServices", {
      getServicesByType: "getServicesByType",
      getServices: "getServices",
      getServicesTypes: "getServicesTypes",
    }),
    async openService(service) {
      if (service.active) {
        this.setSessions([])
        this.loadingServices = true
        this.setCurrentService(service) 
        await this.registerConnection({service_id: service.id});
        return
      }
      this.$store.commit("showSnackbar", "Ative o serviço!")
    },
    async checkUrlType() {
      if (this.$route.params.service_type) {
        await this.setCurrentServiceType(this.$route.params.service_type)
      }
    },
  },
  watch: {
    async currentServiceType(newValue, oldValue) {
      if (!newValue) return;
      await this.getServicesByType()
    },
  }
};


</script>

<style lang="scss" scoped>
.backgroud {
  height: 100vh;
}

.rounded-card {
  border-radius: 10px !important;
  margin: 20px;

  :hover {
    border-radius: 20px;

    cursor: pointer;
  }
}

.card {
  color: #424242;
  font-size: 32px;
  font-weight: 500;
}

.card-borde-1 {
  border-top: 5px solid #f8ca55;
}

.card-borde-2 {
  border-top: 5px solid #08977e;
}

.card-borde-3 {
  border-top: 5px solid #eaaa59;
}

.card-borde-9 {
  border-top: 5px solid #00e58d;
}

.card-borde-8 {
  border-top: 5px solid #00606a;
}
.card-borde-6 {
  border-top: 5px solid rgb(201, 201, 201);
}

.big-numbers {
  font-size: 30px;
}

.little-text {
  font-size: 20px;
  text-align: center;
}

.pending {
  color: #ffffff;
  background: #f4511e;
  border-radius: 10px;

  :hover {
    border-radius: 10px;
    background: #f4511e;
  }
}

.news {
  color: #ffffff;
  background: #fb8c00;
  border-radius: 10px;

  :hover {
    border-radius: 10px;
    background: #fb8c00;
  }
}

.inprogress {
  color: #ffffff;
  background: #0288d1;
  border-radius: 10px;

  :hover {
    border-radius: 10px;
    background: #0288d1;
  }
}
</style>